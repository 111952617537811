<template>
	<div class="detailContent">
		<el-form :model="dataForm" :rules="dataRule" ref="formData" label-width="80px">
			<el-form-item label="标题" prop="title">
				<el-input v-model="dataForm.title" placeholder="请输入标题"></el-input>
			</el-form-item>
			<el-form-item label="协议ID" prop="policyId">
				<el-input v-model="dataForm.policyId" placeholder="协议ID"></el-input>
			</el-form-item>
			<el-form-item label="状态" prop="status">
				<el-radio-group v-model="dataForm.status">
					<el-radio v-for="(item, index) in statusList" :label="index + 1" :key="index + 1">{{ item }}
					</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="内容" prop="content">
				<!-- <el-input v-model="dataForm.content" type="textarea" style="width:400px;" placeholder="请输入内容"></el-input> -->
				<div id="wedit"></div>
			</el-form-item>
			<el-form-item class="buttons">
				<el-button @click="back()">取消</el-button>
				<el-button type="primary" @click="dataFormSubmit()">确认提交</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
import WangEditor from 'wangeditor';

export default {
	name: 'bannerOption',
	data () {
		return {
			statusList: ['启用', '禁用'],
			imgList: [],
			dataForm: {
				id: 0,
				status: 1,
				title: '',
				content: '',
				policyId: ''
			},
			dataRule: {
				title: [
					{ required: true, message: '请输入标题', trigger: 'blur' }
				],
				/* content: [
					{ required: true, message: '请输入内容', trigger: 'blur' }
				], */
				policyId: [
					{ required: true, message: '请输入协议ID', trigger: 'blur' }
				],
				status: [
					{ required: true, message: '请选择状态', trigger: 'blur' }
				]
			},
			uploadUrl: process.env.VUE_APP_BASE_URL + '/mnt/upload-file',
			uploadHeaders: {},
			dialogImageUrl: '',
			dialogVisible: false,
			editor: null
		};
	},
	beforeCreate () {
		if (this.$route.query.id) {
			this.$route.meta.navName = '修改协议';
		} else {
			this.$route.meta.navName = '新增协议';
		}
	},
	created () {
		if (this.$route.query.id) {
			this.dataForm.id = this.$route.query.id;
			this.getDetail(this.$route.query.id);
		}
	},
	mounted () {
		const editor = new WangEditor('#wedit');
		// 配置 onchange 回调函数，将数据同步到 vue 中
		editor.config.onchange = (newHtml) => {
			this.dataForm.content = newHtml;
		};
		// 创建编辑器
		editor.create();
		this.editor = editor;
	},
	methods: {
		back () {
			this.$router.go(-1);
		},
		getDetail (id) {
			this.$root.request('policyDetail', { id }).then((data) => {
				if (data) {
					this.dataForm = {
						id: this.$route.query.id,
						title: data.policy.title,
						policyId: data.policy.policyId,
						status: data.policy.status,
						content: data.policy.content
					};
					this.editor.txt.html(data.policy.content);
				}
			});
		},
		dataFormSubmit () {
			if (this.dataForm.id) {
				this.$refs.formData.validate((valid) => {
					if (valid) {
						this.$root.request('policyUpdate', {
							...this.dataForm
						}).then((data) => {
							if (data) {
								this.$root.elMsg('操作成功', 'success', () => {
									if (this.$route.query.id) {
										this.back();
									} else {
										this.$refs.formData.resetFields();
									}
								}, 1000);
							}
						}).catch(e => {
							this.$root.elMsg(e.msg, 'error');
						});
					} else return false;
				});
			} else {
				this.$refs.formData.validate((valid) => {
					if (valid) {
						this.$root.request('policyAdd', {
							...this.dataForm
						}).then((data) => {
							if (data) {
								this.$root.elMsg('操作成功', 'success', () => {
									if (this.$route.query.id) {
										this.back();
									} else {
										this.$refs.formData.resetFields();
									}
								}, 1000);
							}
						}).catch(e => {
							this.$root.elMsg(e.msg, 'error');
						});
					} else return false;
				});
			}
		},
		beforeImgUpload (file) {
			if (['image/jpeg', 'image/png'].indexOf(file.type) === -1) {
				this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
				return false;
			}
			return true;
		},
		handlePreview (file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		handleImgSuccess (res, file, fileList) {
			this.imgList = fileList.slice(-1);
			if (this.imgList.length) {
				this.dataForm.imgUrl = this.imgList[0].response.data.url;
			}
		}
	}
};
</script>
<style lang="less" scoped>
/deep/ .el-input {
	width: auto !important;
}
.avatar {
		width: 100%;
		height: 100%;
		display: block;
	}
</style>
<style>
.hide .el-upload--picture-card {
    display: none;
}
</style>