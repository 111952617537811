var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detailContent" },
    [
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.dataForm,
            rules: _vm.dataRule,
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标题", prop: "title" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入标题" },
                model: {
                  value: _vm.dataForm.title,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "title", $$v)
                  },
                  expression: "dataForm.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "协议ID", prop: "policyId" } },
            [
              _c("el-input", {
                attrs: { placeholder: "协议ID" },
                model: {
                  value: _vm.dataForm.policyId,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "policyId", $$v)
                  },
                  expression: "dataForm.policyId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.dataForm.status,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "status", $$v)
                    },
                    expression: "dataForm.status"
                  }
                },
                _vm._l(_vm.statusList, function(item, index) {
                  return _c(
                    "el-radio",
                    { key: index + 1, attrs: { label: index + 1 } },
                    [_vm._v(_vm._s(item) + "\n\t\t\t\t")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "内容", prop: "content" } }, [
            _c("div", { attrs: { id: "wedit" } })
          ]),
          _c(
            "el-form-item",
            { staticClass: "buttons" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.back()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit()
                    }
                  }
                },
                [_vm._v("确认提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }